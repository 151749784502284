import { ref, onBeforeUnmount } from 'vue'

const observer = ref(null)
const targets = ref([])
const widths = ref([])
const heights = ref([])
const tops = ref([])
const bottoms = ref([])

if (!isServer()) {
    observer.value = new ResizeObserver(
        (entries) => {
            entries.forEach((entry) => {
                const targetIndex = targets.value.findIndex(t => t.value === entry.target)
                widths.value[targetIndex].value = entry?.borderBoxSize?.[0]?.inlineSize
                heights.value[targetIndex].value = entry?.borderBoxSize?.[0]?.blockSize
                tops.value[targetIndex].value = entry?.contentRect?.top
                bottoms.value[targetIndex].value = entry?.contentRect?.bottom
            })
        },
    )
}

function useResizeObserver() {
    const width = ref(0)
    const height = ref(0)
    const top = ref(0)
    const bottom = ref(0)
    let targetElement = null

    function observe(elRef) {
        if (!isServer()) {
            targetElement = elRef
            targets.value.push(elRef)
            widths.value.push(width)
            heights.value.push(height)
            tops.value.push(top)
            bottoms.value.push(bottom)

            return {
                width, height, top, bottom,
            }
        }

        return {
            width, height, top, bottom,
        }
    }

    onMounted(() => {
        if (!isServer()) {
            observer.value.observe(targetElement.value)
        }
    })

    onBeforeUnmount(() => {
        if (!isServer()) {
            observer.value.unobserve(targetElement.value)
            const targetIndex = targets.value.findIndex(t => t.value === targetElement.value)
            targets.value.splice(targetIndex, 1)
            widths.value.splice(targetIndex, 1)
            heights.value.splice(targetIndex, 1)
            tops.value.splice(targetIndex, 1)
            bottoms.value.splice(targetIndex, 1)
        }
    })

    return { observer, observe }
}

export { useResizeObserver }
