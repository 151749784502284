<script setup>
import { onMounted, ref } from 'vue'
import BrowserInfo from '@smartbear/browser-info'

if (!isServer()) {
    BrowserInfo.detect()
}
const engine = 'webgl'
// if (BrowserInfo.name === 'Safari' || BrowserInfo.os === 'iOS') {
// A bug in recent Mobile Safari makes the browser crash with webgl
// https://bugs.webkit.org/show_bug.cgi?id=268071
// engine = 'canvas'
// }

const root = ref(null)

const props = defineProps({
    minRadius: { default: null, type: Number },
    maxRadius: { default: null, type: Number },
    speed: { default: null, type: Number },
    nombreRonds: { default: null, type: Number },
    log: { default: false, type: Boolean },
    colors: { default: null, type: Array },
    allowOverflowX: { default: false, type: Boolean },
    allowOverflowY: { default: false, type: Boolean },
    fixed: { default: false, type: Boolean },
    shouldDraw: { default: true, type: Boolean },
})
const instance = ref(null)

onMounted(() => {
    // if (BrowserInfo.name !== 'Safari') {
    if (props.shouldDraw === true) {
        instance.value = new GradientCircles(
            root.value,
            props.minRadius,
            props.maxRadius,
            props.speed,
            props.nombreRonds,
            props.colors,
            props.allowOverflowX,
            props.allowOverflowY,
            props.log,
            engine,
        )
    }
    // }
})

onBeforeUnmount(() => {
    instance?.value?.destroy()
})
</script>

<template>
    <div
        v-if="shouldDraw"
        ref="root"
        class="gradient-circle left-0 top-0 z-0 size-full"
        :class="fixed ? 'fixed -z-1' : 'absolute'"
    />
</template>
