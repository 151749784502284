import Two from 'two.js'

class Circle {
    constructor(two, minR, maxR, speed, colors, allowOverflowX, allowOverflowY, factor = 1, log = false) {
        this.two = two
        this.maxMovement = ((Math.random() * 500) + 500) / factor
        this.angle = (Math.random() * Math.PI * 2) / factor
        this.radius = Math.random() * (maxR - minR) + minR
        this.speed = ((Math.random() * 2) - 1) * speed // randomly flips direction and speed
        const smallest = this.radius + this.maxMovement
        const largestWidth = this.two.width - this.radius - this.maxMovement
        const largestHeight = this.two.height - this.radius - this.maxMovement
        if (allowOverflowX) {
            this.x = Math.random() * this.two.width
        }
        else {
            this.x = Math.max(smallest, Math.min(largestWidth, Math.random() * this.two.width))
        }
        if (allowOverflowY) {
            this.y = Math.random() * this.two.height
        }
        else {
            this.y = Math.max(smallest, Math.min(largestHeight, Math.random() * this.two.height))
        }
        const myColor = colors[Math.floor(Math.random() * colors.length)]
        this.log = log

        this.rectangle = this.two.makeRectangle(this.x, this.y, this.radius * 2, this.radius * 2)
        this.rectangle.noStroke()

        const gradient = this.two.makeRadialGradient(
            0,
            0,
            this.radius,
            new Two.Stop(0, `rgba(${myColor}, 1)`),
            new Two.Stop(0.66, `rgba(${myColor}, 0.25)`),
            new Two.Stop(1, `rgba(${myColor}, 0)`),
        )
        gradient.units = 'userSpaceOnUse'
        this.rectangle.fill = gradient
    }

    draw() {
        this.angle += this.speed
        if (this.log) {
            console.log(this.x + Math.sin(this.angle) * this.maxMovement)
        }
        this.rectangle.position.x = this.x + Math.cos(this.angle) * this.maxMovement
        this.rectangle.position.y = this.y + Math.sin(this.angle) * this.maxMovement
    }
}

export { Circle }
