import Two from 'two.js'
import Tempus from '@darkroom.engineering/tempus'

class GradientCircles {
    constructor(element, minRadius, maxRadius, speed, nombre, colors = [], allowOverflowX = false, allowOverflowY = false, log = false, engine = 'webgl') {
        let engineType = Two.Types[engine]

        // const precision = 1000;
        this.factor = (element.parentElement.clientWidth / 150)
        this.factorY = (element.parentElement.clientHeight / 150)
        // this.factor = 20;
        const w = 150
        const h = 150

        this.two = new Two({
            autostart: false,
            width: w,
            height: h,
            // fitted: true,
            type: engineType,
            powerPreference: 'high-performance',
        })

        this.unsubscribeTempus = Tempus.add(() => {
            this.two.update()
        }, 1)
        this.two.appendTo(element)
        this.two.renderer.domElement.style.transform = `translate3d(0,0,0) scale(${this.factor}, ${this.factorY})`
        this.two.renderer.domElement.style.transformOrigin = 'top left'
        this.two.bind('resize', () => {
            this.factor = (element.parentElement.clientWidth / 150)
            this.factorY = (element.parentElement.clientHeight / 150)
            this.two.renderer.domElement.style.transform = `translate3d(0,0,0) scale(${this.factor}, ${this.factorY})`

            this.two.width = newW
            this.two.height = newH
            this.two.renderer.setSize(w, h)

            this.drawCircles()
        })
        this.two.bind('update', () => {
            this.drawCircles()
        })

        this.circlesNum = nombre
        this.minRadius = minRadius / this.factor
        this.maxRadius = maxRadius / this.factor
        this.speed = speed
        this.log = log
        this.colors = colors
        this.allowOverflowX = allowOverflowX
        this.allowOverflowY = allowOverflowY

        this.createCircles()
        this.drawCircles()
    }

    createCircles() {
        this.circles = []
        for (let i = 0; i < this.circlesNum; i += 1) {
            this.circles.push(new Circle(
                this.two,
                this.minRadius,
                this.maxRadius,
                this.speed,
                this.colors,
                this.allowOverflowX,
                this.allowOverflowY,
                this.factor,
                this.log,
            ))
        }
    }

    drawCircles() {
        this.circles.forEach(circle => circle.draw())
    }

    destroy() {
        this.unsubscribeTempus()
        this.two.pause()
        this.two.clear()
        this.two.renderer.domElement.remove()
        this.two.release()
        this.two = null
    }
}

export { GradientCircles }
